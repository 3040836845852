import styled from "styled-components"
import { fonts, device, colors, limiter, paddingTop } from "../utils/styles"
import Img from "gatsby-image"

export const Projects = styled.section`
  ${limiter};
  ${paddingTop};
  min-height: 100vh;
  max-width: 450px;

  @media ${device.tablet} {
    max-width: 1340px;
  }
`

export const Header = styled.header``

export const HeaderTitle = styled.h1`
  font-weight: ${fonts.fontBold};
  font-size: 1.45rem;
  line-height: 1.9rem;
  margin-bottom: 0.5em;

  @media ${device.tablet} {
    font-size: 2.6rem;
    line-height: 3.15rem;
  }

  @media ${device.laptop} {
    font-size: 3.6rem;
    line-height: 4.1rem;
    margin-bottom: 0.3em;
  }
`

export const Content = styled.section`
  display: flex;
  flex-direction: column;

  @media ${device.laptop} {
    flex-direction: row;
  }
`

export const ProjectsList = styled.ul`
  padding-top: 1.6em;

  @media ${device.laptop} {
    padding-right: 2.2em;
    padding-top: 4em;
  }
`

export const Image = styled(Img)`
  margin-top: 0.4em;
  transition: all 0.2s ease-in-out;

  @media ${device.tablet} {
    margin-top: 0;
  }

  &:hover {
    filter: grayscale(100%);
    transform: scale(1.05);
  }
`

export const Project = styled.li`
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 2.9em;
  color: ${colors.mainBlack};

  &:hover ${Image} {
    filter: grayscale(100%);
    transform: scale(1.05);
  }

  @media ${device.tablet} {
    flex-direction: row;
  }

  @media ${device.laptopL} {
    width: 1340px;
    margin-bottom: 3.7em;
  }
`

export const ProjectContent = styled.div`
  @media ${device.tablet} {
    flex: 0.6;
    padding-left: 1.3em;
  }

  @media ${device.laptop} {
    flex: 0.65;
    padding-left: 2em;
  }
`

export const ImageWrapper = styled.figure`
  overflow: hidden;
  max-height: 200px;

  @media ${device.tablet} {
    flex: 0.4;
  }

  @media ${device.laptop} {
    flex: 0.35;
  }

  @media ${device.laptopL} {
    min-height: 230px;
  }
`

export const ProjectTitle = styled.h2`
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: ${fonts.fontBold};

  @media ${device.mobileL} {
    font-size: 1.15rem;
    line-height: 1.5rem;
  }

  @media ${device.tablet} {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }

  @media ${device.laptop} {
    font-size: 1.6rem;
    line-height: 2.15rem;
  }

  @media ${device.laptopL} {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
`

export const Intro = styled.p`
  font-size: 0.67rem;
  text-transform: uppercase;
  margin-top: 1.2em;
  margin-bottom: 0.3em;
  font-weight: ${fonts.fontBold};

  @media ${device.tablet} {
    margin-top: 1.5em;
  }

  @media ${device.laptop} {
    margin-top: 2.3em;
  }
`

export const ProjectInfo = styled.p`
  font-size: 0.92rem;
  line-height: 1.35rem;
  font-weight: ${fonts.fontRegular};
  color: ${colors.gray};

  @media ${device.laptop} {
    font-size: 1.1rem;
    line-height: 1.65rem;
  }
`

export const Line = styled.span`
  display: block;
  width: 63%;
  height: 1px;
  background: ${colors.gray};
  margin-bottom: 0.5em;
`

export const Space = styled.span`
  display: block;
  width: 100%;
  height: 1.5em;

  @media ${device.tablet} {
    height: 3em;
  }
`

export const Warning = styled(ProjectInfo)`
  margin-bottom: 1.5em;
`
