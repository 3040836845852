import React from "react"
import { graphql, Link } from "gatsby"
import Fade from "react-reveal/Fade"
import PageTransition from "gatsby-plugin-page-transitions"
import {
  Projects,
  Header,
  HeaderTitle,
  Content,
  ProjectsList,
  Project,
  ImageWrapper,
  Image,
  ProjectContent,
  ProjectTitle,
  Intro,
  ProjectInfo,
  Line,
  Space,
  Warning,
} from "../sections/projects.styles"

import SEO from "../components/seo"

const labels = {
  spektakle: "Projekty autorskie",
  edukacja: "Edukacja",
  pokazy: "Pokazy dla firm",
  wspolpraca: "Współpraca choreograficzna",
}

const ProjectsByCategory = ({ data, pageContext: { category } }) => {
  const projects = data.allContentfulProject.edges

  const label = labels[category]

  return (
    <>
      <SEO
        title={`${label} | Dzikistyl Company`}
        keywords={[
          `${label} DzikiStyl Company`,
          `Gdańsk ${label}`,
          `Gdynia ${label}`,
          `Trójmiasto ${label}`,
        ]}
        description="W swoim dorobku artystycznym Teatr Dzikistyl Company posiada bogaty zbiór różnego rodzaju działań artystycznych i edukacyjnych oraz spektakli teatralnych."
      />
      <PageTransition>
        <Projects>
          <Header>
            <HeaderTitle>{label}</HeaderTitle>
          </Header>
          <Content>
            <ProjectsList>
              {projects.length === 0 && <Warning>Brak wyników.</Warning>}
              {projects.map(({ node: { title, slug, premiere, images } }) => {
                return (
                  <Fade bottom key={slug}>
                    <Link to={`/${category}/${slug}`}>
                      <Project>
                        <ImageWrapper>
                          <Image fluid={images[0].fluid} alt={title} />
                        </ImageWrapper>
                        <ProjectContent>
                          <Line />
                          <ProjectTitle>{title}</ProjectTitle>
                          <Intro>Premiera</Intro>
                          <ProjectInfo>{premiere}</ProjectInfo>
                        </ProjectContent>
                      </Project>
                    </Link>
                  </Fade>
                )
              })}
            </ProjectsList>
          </Content>
        </Projects>
        <Space />
      </PageTransition>
    </>
  )
}

export default ProjectsByCategory

export const categoryQuery = graphql`
  query ProjectByCategory($category: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    allContentfulProject(
      filter: { category: { eq: $category } }
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          title
          slug
          premiere
          images {
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
    }
  }
`

// export const categoryQuery = graphql`
//   query ProjectByCategory($category: String!) {
//     site {
//       siteMetadata {
//         title
//         author
//       }
//     }
//     allContentfulProject(
//       filter: { category: { eq: $category } }
//       sort: { fields: [date], order: DESC }
//     ) {
//       edges {
//         node {
//           title
//           slug
//           premiere
//           images {
//             fluid {
//               base64
//               tracedSVG
//               aspectRatio
//               src
//               srcSet
//               srcWebp
//               srcSetWebp
//               sizes
//             }
//           }
//         }
//       }
//     }
//   }
// `
